import React from "react";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import ReactGA from "react-ga";

import {
  FilterAccordionTags,
  FilterAccordionTitle,
  FilterAccordionToggle,
} from "components/filter-accordions";

import { DrupalFiltersPropertyKeys } from "./types";

import "./Filters.scss";
import { FilterContext } from "components/filter-context";

function Filters({
  onHandleSubmit,
  onHandleReset,
}: {
  onHandleSubmit: () => void;
  onHandleReset: () => void;
}) {
  const {
    selectedFilters,
    setSelectedFilters,
    taxonomyFilters,
  } = React.useContext(FilterContext);

  const onHandleResetButtonClick = () => {
    setSelectedFilters({
      academicYears: [],
      contributions: [],
      illinoisStandards: [],
      intersectionality: [],
      tags: [],
      title: "",
    });

    onHandleReset();
  };

  const allFiltersEmpty =
    selectedFilters &&
    selectedFilters[DrupalFiltersPropertyKeys.contributions].length === 0 &&
    selectedFilters[DrupalFiltersPropertyKeys.intersectionality].length === 0 &&
    selectedFilters[DrupalFiltersPropertyKeys.illinoisStandards].length === 0 &&
    selectedFilters[DrupalFiltersPropertyKeys.academicYears].length === 0 &&
    selectedFilters[DrupalFiltersPropertyKeys.tags].length === 0 &&
    selectedFilters[DrupalFiltersPropertyKeys.title].length === 0;

  return (
    <Form className="Filters">
      <h3>Filters</h3>
      <Accordion>
        {taxonomyFilters && (
          <>
            <FilterAccordionTitle
              eventKey={DrupalFiltersPropertyKeys.title}
              title="Keywords/Name"
            />
            {taxonomyFilters.contributions && (
              <FilterAccordionToggle
                eventKey={DrupalFiltersPropertyKeys.contributions}
                title="Subject/Field"
                terms={taxonomyFilters.contributions}
              />
            )}
            {taxonomyFilters.intersectionality && (
              <FilterAccordionToggle
                eventKey={DrupalFiltersPropertyKeys.intersectionality}
                title="Intersectionality"
                terms={taxonomyFilters.intersectionality}
              />
            )}
            {taxonomyFilters.illinoisStandards && (
              <FilterAccordionToggle
                eventKey={DrupalFiltersPropertyKeys.illinoisStandards}
                helpText={
                  <p>
                    For code descriptions{" "}
                    <ReactGA.OutboundLink
                      eventLabel="curriculumCodesLink"
                      to="https://legacyprojectchicago.org/education/school-codes"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      click here.
                    </ReactGA.OutboundLink>
                  </p>
                }
                title="Curriculum Standard"
                terms={taxonomyFilters.illinoisStandards}
              />
            )}
            {taxonomyFilters.academicYears && (
              <FilterAccordionToggle
                eventKey={DrupalFiltersPropertyKeys.academicYears}
                title="Academic Year"
                terms={taxonomyFilters.academicYears}
              />
            )}
            <FilterAccordionTags
              eventKey={DrupalFiltersPropertyKeys.tags}
              title="Tags"
            />
          </>
        )}
      </Accordion>
      <div className="Filters-buttons">
        <Button
          block
          variant="primary"
          onClick={onHandleSubmit}
          disabled={allFiltersEmpty}
        >
          Submit
        </Button>
        {allFiltersEmpty ? (
          <p className="Filters-buttonsText">Select filters to search</p>
        ) : (
          <Button block variant="link" onClick={onHandleResetButtonClick}>
            Reset
          </Button>
        )}
      </div>
      <p className="Filters-sidebarExternalLink">
        <ReactGA.OutboundLink
          eventLabel="returnToLegacyProject"
          to="https://legacyprojectchicago.org/caci_implementationguidance_file"
          target="_blank"
          rel="noopener noreferrer"
        >
          DOWNLOAD: Teacher Guidance
        </ReactGA.OutboundLink>
      </p>
      <p className="Filters-sidebarExternalLink">
        <ReactGA.OutboundLink
          eventLabel="returnToLegacyProject"
          to="https://www.ilsafeschools.org/virtual-pd-sessions"
          target="_blank"
          rel="noopener noreferrer"
        >
          REGISTER: Professional Development
        </ReactGA.OutboundLink>
      </p>
      <p className="Filters-sidebarExternalLink">
        <ReactGA.OutboundLink
          eventLabel="returnToLegacyProject"
          to="https://www.ilsafeschools.org/join-our-newsletter"
          target="_blank"
          rel="noopener noreferrer"
        >
          SIGN-UP: Curriculum Newsletter
        </ReactGA.OutboundLink>
      </p>
      <p className="Filters-sidebarExternalLink">
        <ReactGA.OutboundLink
          eventLabel="returnToLegacyProject"
          to="https://legacyprojectchicago.org/explore/advanced"
          target="_blank"
          rel="noopener noreferrer"
        >
          RETURN: Legacy Project Website
        </ReactGA.OutboundLink>
      </p>
    </Form>
  );
}

export default Filters;
