import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import ReactGA from "react-ga";

import "./Footer.scss";

function Footer() {
  const date = new Date();

  return (
    <Row className="Footer">
      <Col md={{ span: 9, offset: 1 }}>
        <footer className="Footer-contentWrap">
          <div className="Footer-copyright">
            Copyright © {date.getFullYear()}{" "}
            <ReactGA.OutboundLink
              className="Footer-legacyProjectLink"
              eventLabel="legacyProjectHomepage"
              to="https://legacyprojectchicago.org"
              rel="noopener noreferrer"
              target="_blank"
            >
              The Legacy Project
            </ReactGA.OutboundLink>
          </div>
          <div className="Footer-license">
            All rights reserved. No part of this web site may be used or
            reproduced in any manner whatsoever without written permission.
          </div>
        </footer>
      </Col>
    </Row>
  );
}

export default Footer;
